import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';

const Faq = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="FAQ"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section id="faq_section"
                                 style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "50px"}}>
                            <h1 style={{marginBottom: "50px", textAlign: "center"}}>FAQ</h1>
                            <h3 style={{marginTop: "20px"}}>- What are dummy flight tickets?</h3>
                            <p>Dummy flight tickets are temporary, non-booked flight reservations used for visa
                                applications or proof of onward travel. They are not actual flight tickets but provide
                                the necessary documentation for visa applications and other travel requirements.</p>
                            <h3 style={{marginTop: "20px"}}>- Are dummy flight tickets accepted for visa
                                applications?</h3>
                            <p>Yes, dummy flight tickets are commonly accepted by embassies and consulates for visa
                                applications as proof of travel intent. However, it is always recommended to check the
                                specific requirements of the embassy or consulate you are applying to.</p>
                            <h3 style={{marginTop: "20px"}}>- How long is the dummy flight ticket valid?</h3>
                            <p>Dummy flight tickets are usually valid for 48-72 hours. They are meant for short-term use
                                to support your visa application or travel plans. Be sure to check the validity period
                                specified in your email. </p>
                            <h3 style={{marginTop: "20px"}}>- Is it safe to use dummy flight tickets for visa
                                applications?</h3>
                            <p>Yes, it is safe and legal to use dummy flight tickets for visa applications as long as
                                they meet the requirements of the embassy or consulate. Our service ensures that your
                                dummy ticket appears as a legitimate reservation to fulfill your travel documentation
                                needs.</p>
                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default Faq;
